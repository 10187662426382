@import "src/styles/variables";

.button-component {
  height: 40px !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  color: $dark-grey !important;
  border-color: $input-border !important;

  span {
    vertical-align: middle;
  }

  .btn-icon::before {
    color: $primary-color !important;
  }

  .icon-filter {
    vertical-align: top;
  }

  &.ant-btn-primary {
    color: #fff !important;
    background: $primary-color !important;
    border-color: unset !important;

    .btn-icon::before {
      color: #fff !important;
    }
  }

  &.secondary {
    background: #b2b4b5 !important;
    color: white !important;
  }
}

.ant-btn-text {
  height: 52px;
  background-color: #ffffff !important;
}

.ant-btn-loading {
  width: auto !important;
}

.btn-icon {
  margin-right: 12px;
  font-size: 18px;
  color: #4f4f4f;
  vertical-align: middle;
}
