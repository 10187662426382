@import "src/styles/variables";

.risk-assesment {
  width: 100%;
  margin: auto;

  h1 {
    margin: 0;
    font-size: 22px;
    font-weight: bolder;
    @media (max-width: 995px) {
      font-size: 19px;
    }
  }

  .placeholder__wrapper {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  p {
    width: 60%;
    margin: 3rem auto;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .submit-btn {
    margin: 3rem auto;
    width: 300px;
    display: flex;
    justify-content: center;

    .ant-btn.ant-btn-primary {
      border-top: 2px solid #2ab8f5;
      width: 100% !important;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }
}
