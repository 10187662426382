
/* Color palette */
$primary-color: #002F87;
$secondary-color: #006FBA;
$light-white: #B2B4B5;
$primary-light-color: #0D494E10;
$primary-black: #303030;
$dark-black: #2A2A2A;
$blue: #00ADE8;
$light-blue: #2AB8F5;
$dark-blue: #22509D;
$grey: #A9ABAC;
$light-grey: #00000015;
$lighter-grey: #B2B4B51A;
$lightest-grey: #1C416D0D;
$dark-grey: #8898AA;
$white: #ffffff;
$mid-dark-yellow: #ffa31a52;
$mid-dark-purple: #5c4e791a;
$mid-dark-green: #a4d0581a;
$success-color: green;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-border: #b2b4b5;
$link-color: #2AB8F5;
$date-picker-bg: #E9E9E9;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;


/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;
