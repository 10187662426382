.page-header__container {
  padding: 2.5rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;

  .page-header__details {
    animation: slideRight 1000ms ease forwards;
    .subscription-upgrade {
      color: #002f87;
      background: transparent;
      border: 1px solid #002f87;
      border-radius: 14px;
      padding-bottom: 10px;
      span {
        padding-bottom: 5px;
      }
    }

    .page-header__details--title {
      margin: 0 0 0.5rem 0;
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;

      .page-header__details--title-chip {
        margin: 0 1rem;
        padding: 0.25rem 1rem;
        display: inline-flex;
        font-size: 14px;
        border: 0;
        background: #ffa417;
        border-radius: 20px;
        cursor: pointer;
        color: #fff;
        align-items: center;

        i {
          margin: 0 0.25rem;
        }

      
      }
      .page-header__details--title-upgrade{
        margin: 0 1rem 0 0.2rem;
        padding: 0.3rem 0.5rem 0.3rem 0.8rem;
        display: inline-flex;
        font-size: 14px;
        border: 0;
        background: rgba(67, 213, 174, 0.26);
        // opacity: 0.26;
        border-radius: 5px;
        cursor: pointer;
        align-items: center;
        span{
          color: #43D5AE;
        }
        i {
          margin: 0 0.25rem;
        }
        .page-header__details--upgrade-icon{
          width: 25px;
          height: 21px;
        }

      }

      .page-header__details--title-badge {
        margin: 0 1rem 0 1rem;
        padding: 0.2rem 0.7rem 0.2rem 0.4rem;
        min-width: 65px;
        height: 33px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        border-radius: 20px;
        display: inline-flex;
        align-items: center;
        background: #ffa417;

        .icon-subscription::before {
          margin: 5px;
        }

        &.Platinum {
          background: #2ab8f5;
        }

          &.Chama {
          background: #a4cf57;
        }

        &.Silver {
          background: #002f87;
        }
      }
    }

    .page-header__details--subtitle {
      margin: 0;
      display: flex;
      width: fit-content;
      color: #707070;
      font-size: 16px;
      text-transform: capitalize;

      hr {
        margin: auto 0.75rem;
        height: 5px;
        width: 5px;
        border: none;
        border-radius: 10px;
        background: silver;
        opacity: 0.2;
      }
    }

    &.subtitle-placeholder {
      .page-header__details--subtitle {
        min-height: 25px;
      }
    }
  }

  .page-header__wallet--container {
    display: flex;
    animation: slideLeft 500ms ease forwards;
    z-index: 999;
    .page-header__investment{
      padding: 0 0.5rem 0 1rem;
      display: flex;
      flex-direction: row;
      gap:10px;
    >span{
      font-size:14.5px;
      color: #303030;
    }
    }

    .page-header__investment__main{
      display: flex;
      flex-direction: column;
   
    }
    .page-header__wallet {
      display: flex;
      .page-header__wallet--heading{
        color: #B2B4B5;
        font: normal normal normal 14px/19px SF Pro Display;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.125rem;
      }
      .page-header__wallet--amount{
        font-size: 0.875rem;
        font-weight: 600;
        color: #303030;
        opacity: 1;
        padding-top: -0.1875rem;
      }
      min-width: 200px;
      .page-header__wallet--icon{
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.125rem;
      }
      .page-header__wallet-text {
        display: inline;
        color: #b2b4b5;
        align-items: center;
        display: flex;
      }

      .page-header__wallet-hidden {
        display: inline;
        visibility: hidden;
      }
        .page-header__wallet-balance {
        font-weight: 600;
        color: #303030;
        display: inline;
      }
    }

    .page-header__wallet-divider{
      height: 1.6em;
      margin: 0 1.25rem 0 1.25rem;
    }

    .wallet__notification {
      width: 25px;
      height: 25px;
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      &.badge::before {
        content: "\2022";
        color: hsla(9, 100%, 72%, 0.5);
        font-size: 350%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -50%);
      }

      &.badge::after {
        content: "\2022";
        color: #ff8872;
        font-size: 200%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(17%, -43%);
      }
    }
    .wallet__notifications-menu{
      overflow: auto;
      width: 25rem;
      z-index: 999;
      border-radius: 0.7rem;
      padding: 1rem;
      background: #FFFFFF;
      position: absolute;
      right: 0rem;
      top: 3rem;
      border: 0.1rem solid #F1F9FE;
  
      &>.ant-menu-vertical {
        border: 0;
      }
      
      &~.menu-item {
        &:hover {
        background: #F1F9FE;
        }
      }
      
      &.navbar-user-menu {
        &>.ant-menu-vertical {
          border: 0;
          padding: 0;
        }
      }

      .wallet__notifications-header {
        padding: 0.6rem;
      
        .notifications-header__icon{
          margin-top: -0.75rem;
          width: 1.8125rem;
          height: 1.5625rem;
          cursor: pointer;
          position: relative;
      
          img {
            width: 100%;
            height: 100%;
          }
        }
        .notifications-header__text{
          font-weight: 600;
          font-size: 0.9375rem;
          text-shadow: 0.0063rem 0 #000000;
          margin-left: -0.4375rem;
        }
        .notifications-header__mark-all{
          p{
            cursor: pointer;
            font-weight: 800;
            font-size: 0.9375rem;
            line-height: 1.5715;
            color: #002f87;
            text-shadow: 0.0063rem 0 #002f87;
            margin-right: 0.625rem;
          }
        }
      }

      .wallet__notifications-menu__menu{
        height: 19rem;
        overflow: scroll;
      }

      div.ant-col.ant-col-3.wallet__notifications-menu__icon{
        display: flex;
        width: 1.3rem;
        height: 1.3rem;
        max-width: 2rem;
        margin-top: 1.25rem;
      }

      div.ant-row.wallet__notifications-menu__item-row{
        padding: 1rem;
        &span {
          line-height: 1;
        }

        .text--blue {
          color:  #002f87!important;
          white-space: pre-wrap;
        }
      
        .text--black {
          color: #000000 !important;
          white-space: pre-wrap;
        }

      }
      
      .ant-menu-item.ant-menu-item-only-child.wallet__notifications-menu__item{
          padding: 0.3rem 0.4rem 0 1rem;
          width: 100%;
          height: auto;
          line-height: 1.1875rem;
          &:hover{
            background:#FAFAFCF4;
        }
      }
      .notification__item-row-date{
        font-size: 0.75rem;
        span{
          margin-top: 0.3125rem;
        }
      }
    }

   @media (max-width: 960px) {
      .page-header__wallet-divider{
        display: none;
      }

      .wallet__notification{
        display: none;
      }
    }
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media (max-width: 960px) {
  .page-header__container {
    flex-wrap: wrap;
    padding: 30px 2rem 1rem;

    .page-header__details {
      animation: slideRight 1000ms ease forwards;

      .page-header__details--subtitle {
        flex-wrap: wrap;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
}
