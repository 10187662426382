@import "src/styles/variables";

.terms-and-policy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    span {
        &:first-child {
            margin-right: 8px;
        }
    }
    .dot {
        background: $primary-black;
        height: 6px;
        width: 6px;
        border-radius: 100px;
        margin-right: 8px;
        opacity: 0.3;
    }
}