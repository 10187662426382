.login {
  form {
    width: 60%;
    margin-left: 5vw;

    @media (max-width: 995px) {
      width: 100%;
      max-width: 400px;
      margin: 2rem;
      margin-top: 0;
    }

    @media (max-width: 995px) {
      .login__logo {
        width: 100%;
        margin: auto;
        height: 135px;
        background: url("./../../../assets/icons/logo\ -\ svg/vuka-logo-dark.svg") center no-repeat;

        background-size: 120px 50px;
        margin-bottom: 2rem;
      }
    }

    h1 {
      margin-top: 4rem;
      margin-bottom: 2rem;
      font-weight: bolder;

      @media (max-width: 995px) {
        margin-top: 0;
        margin-right: 8%;
      }
    }

    .submit-btn {
      margin-top: 3rem !important;
      display: flex;
      justify-content: center;

      .ant-btn.ant-btn-primary {
        width: 60% !important;
        text-align: center;

        @media (max-width: 995px) {
          width: 100% !important;
        }
      }
    }

    .login-link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}