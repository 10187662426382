.customer-profile__container {
  color: #303030;

  .customer-profile__body {
    .customer-profile__body-basic-details {
      margin: 1rem 0;
      position: relative;

      .ant-col {
        padding: 0 1rem;
      }

      .subscription-details {
        padding: 2rem;
        background: #ffa417;
        box-shadow: 0px 10px 20px #1c416d0d;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;

        @media (max-width: 960px) {
          margin-top: 20%;
        }

        .subscription {
          margin: 0;
          font-size: 24px;
          font-weight: 500;
        }

        .subscription-upgrade {
          color: #fff;
          background: #ffffff1a;
          border: 1px solid #ffffff;
          border-radius: 14px;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .responsiveNameInput {
    width: 125%;
  }
}
