@import "src/styles/variables";

.plan {
  height: 100%;
  box-shadow: 0px 10px 20px #1c416d0d;
  border-radius: 30px;
  border-top-width: 1px;
  border-top-style: solid;
  padding: 1rem;
  background: #fff;
  max-width: 550px;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .plan__description {
    width: 300px;
  }

  @media (max-width: 990px) {
    margin: 1rem 0;
    padding-bottom: 1rem;
    height: fit-content;
  }

  p {
    color: $primary-black;
  }

  .title {
    font-size: 20px;
    font-weight: 900;

    .title-name {
      color: black;
    }
  }

  .price {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;

    span {
      font-weight: 100 !important;
      font-size: 16px;
    }
  }

  .description {
    color: #303030;
    font-size: 14px;

    p:nth-child(2) {
      margin-bottom: 0;
    }

    .icon-playBtn {
      width: 8px !important;
      margin-right: 10px;
      opacity: 0.3;
      margin-bottom: 2px;

      .playBtn::before {
        color: green;
      }
    }
  }

  .subscription-btn__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-weight: 600;
      align-items: center;
      text-align: center;
      background-color: #ffffff !important;
      border-radius: 18px;
      opacity: 1;
      width: 170px;
      min-width: fit-content;
      height: 50px;
      padding: 0 0.5rem;
      box-shadow: none;

      .kes {
        font-size: 12px;
      }

      // @media (max-width: 1100px) {
      //   width: 35%;
      // }
    }
  }
}

.plan:hover {
  border-top-width: 3px;
  padding-top: calc(1rem - 2px);

  .icon-playBtn {
    opacity: 1 !important;
  }
}

.plan:hover button span {
  color: white !important;
}

.plan:hover.silver button {
  background-color: #002f87 !important;
}

.plan:hover.gold button {
  background-color: #ffa417 !important;
}

.plan:hover.platinum button {
  background-color: #2ab8f5 !important;
}

.plan:hover.plan.platinum_plus button {
  background-color: #a4cf57 !important;

  span {
    color: white !important;
  }
}

.plan:hover.diamond button {
  background-color: #002f87 !important;
}

.plan.silver,
.plan.diamond {
  color: #002f87;

  button {
    border: 0.1px solid #002f87 !important;
  }



  p.title,
  span.price,
  span.kes {
    color: #002f87;
  }
}

.plan.gold {
  color: #ffa417 !important;

  button {
    border: 0.1px solid #ffa417 !important;
  }

  p.title,
  span.price,
  span.kes {
    color: #ffa417;
  }

  button {
    background-color: #ffa417;
  }
}

.plan.platinum {
  color: #2ab8f5;

  p.title,
  span.price,
  span.kes {
    color: #2ab8f5;
  }

  button {
    background-color: #2ab8f5;
    border: 0.1px solid #2ab8f5 !important;
  }
}

.plan.chama,
.plan.platinum_plus {
  color: #a4cf57;

  p.title,
  span.price,
  span.kes {
    color: #a4cf57;

    .icon-subscription::before {
      color: #a4cf57;
    }
  }

  button {
    background-color: #666665;
    color: #a4cf57;
    border: 0.1px solid #a4cf57;
  }

  &:hover {
    button {
      span {
        color: #a4cf57 !important;
      }
    }
  }
}

.plan {

  &.hover {
    &.gold {

      .icon-playBtn {
        background-color: red;
      }
    }
  }
}

@media (max-width: 990px) {
  .plan {
    width: calc(100vw - 2rem);
    margin: 0 0 0 1.5rem;

    .plan__description {
      margin-bottom: 1rem;
    }

    .subscription-btn__wrapper {
      width: 100%;
      justify-content: flex-end;
    }
  }
}