.wallet__container {
  width: 100%;
  height: 170px;
  display: flex;

  .wallet__dynamic {
    /* margin: 0 1% 0 15%; */
    min-width: 65% !important;
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(150deg, #002f87 40%, #003caa 40.5%);
    border-top: 3px solid #2ab8f5;
    border-radius: 30px;

    @media (max-width: 960px) {
      flex-wrap: wrap;
      width: 100% !important;
      height: 230px !important;
    }

    @media (max-width: 355px) {
      white-space: initial !important;
      height: 320px !important;
    }

    .wallet__dynamic--balance {
      width: 50%;
      color: #2ab8f5;
      display: flex;
      align-items: center;

      .wallet__dynamic--wallet-icon {
        margin: 1rem;
        padding: 1.25rem;
        border-radius: 100px;
        background-color: #ffffff1a;

        img {
          width: 35px;
          height: 35px;
        }
      }

      .wallet__dynamic--balance--title {
        margin: 0.5rem 0;
        font-size: 1rem;

        @media (max-width: 960px) {
          font-size: clamp(1rem, 2.2vw, 2.5rem);
        }
      }

      .wallet__dynamic--balance--value {
        margin: 0;
        font-size: clamp(1.8rem, 2.2vw, 2.5rem);
        font-weight: bold;

        @media (max-width: 380px) {
          position: relative;
          right: 4%;
        }
      }

      .ant-skeleton {
        .ant-skeleton-input {
          margin: 0.5rem 0;
        }
      }
    }

    .wallet__dynamic--actions {
      justify-content: center;
      align-items: center;

      word-wrap: break-word;

      @media (max-width: 1255px) {
        margin-left: 32px;
        justify-content: center;
        text-align: center;
      }

      button {
        padding: 0 1rem;
        margin: 0.5rem;
        width: 120px;
        height: 48px;
        border-radius: 18px;

        @media (max-width: 960px) {
          width: 102px;
          height: 40px;
          padding: 0 0.5rem;
          margin-bottom: 10px;
        }

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .primary {
        color: #fff;
        background-color: #2ab8f5;
        box-shadow: none;
      }

      .default {
        width: fit-content;
        color: #2ab8f5;
        border: 1px solid #2ab8f5;
        background-color: #2ab8f533;
      }
    }
  }

  .wallet__static {
    margin: 0 0 0 1%;
    width: 34%;
    height: 100%;

    border-radius: 30px;
    border: 1px solid #002f87;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 30px 30px #1c416d0f;

    @media (max-width: 960px) {
      height: 170px;
    }

    @media (min-width: 900px) and (max-width: 1200px) {
      width: 40%;
      padding-right: 1rem;
    }

    @media (max-width: 355px) {
      width: 40%;
      padding-right: 1rem;
    }

    .wallet__static--balance {
      color: #002f87;
      width: 100%;
      display: flex;
      align-items: center;

      .wallet__static--wallet-icon {
        margin: 1rem;
        padding: 1.5rem;
        border-radius: 100px;
        background-color: #002f871a;

        img {
          width: 29px;
          height: 30px;
        }
      }

      .wallet__static--balance--title {
        margin: 0.5rem 0;
        font-size: 16px;
        opacity: 0.8;

        @media (max-width: 960px) {
          font-size: clamp(1rem, 2.2vw, 2.5rem);
        }

        .icon-ic-info-24px::before {
          padding: 0.5rem;
          font-size: 12px;
          opacity: 1;
          max-width: 100%;
        }
      }

      .wallet__static--balance--value {
        margin: 0;
        font-size: clamp(1.8rem, 2.2vw, 2.5rem);
        font-weight: bold;
      }

      .ant-skeleton {
        .ant-skeleton-input {
          margin: 0.5rem 0;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .wallet__container {
    flex-wrap: wrap;
    height: fit-content;

    .wallet__dynamic {
      width: 100%;
      height: 150%;
      /*  padding: 10px; */
      padding-top: 20px;
      margin: auto;
      white-space: nowrap;
    }

    .wallet__dynamic--actions {
      flex-wrap: wrap;

      button {
        width: 102px;
        height: 40px;
        padding: 0 0.5rem;
      }
    }

    .wallet__static {
      flex-wrap: wrap;
      width: 200%;
      margin: 20px 0 20px 0;
      text-align: center;

      /*    .wallet__static--balance {
        justify-content: center;
        align-items: center;
        font-size: 1.7rem;
        display: inline-block;

        .wallet__static--wallet-icon {
          width: 19px;
          height: 20px;
          display: block;
          margin: 0 auto;
          padding: 2rem;
        }
      } */
    }
  }
}

.wallet-form {
  .ant-radio-group {
    width: 100%;

    .ant-radio-wrapper span:not(.ant-radio):not(.ant-radio-inner) {
      width: 100%;
    }

    .ant-radio-wrapper {
      margin-bottom: 1rem;
      display: flex;
      position: relative;

      .ant-radio {
        margin-top: .25rem;
      }

      .ant-col {
        position: static;
      }

      .withdraw-option-bank {

        .option {
          margin-bottom: .5rem;
          font-size: 16px;
        }

        .bank {
          font-size: 14px;
          color: #707070;
          text-align: left;
        }

        .icon-cover {
          background-color: hsla(36, 100%, 55%, 0.1);
          border-radius: 100px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 25px;
          }
        }

        .icon-cover,
        .icon {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translate(0, -50%);
        }
      }

      .input-cover {
        margin: .5rem 0;
        height: 50px;
      }

      .mpesa-option {
        margin-right: .25rem;
        font-size: 16px;
        display: flex;
        justify-content: space-between;

        img {
          height: 25px;
        }
      }
    }
  }
}

.ant-modal.ui-modal.withdraw-req__confirmation {

  .ant-modal-content {
    padding: 1rem 2rem;

    .ant-modal-body {
      padding: 3rem 1rem 2rem;
      font-size: 18px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;

      .ant-btn:not(.ant-btn-primary) {
        border: 1px solid #B2B4B5;
        margin: 0;
        height: 40px;
        display: inline-block;
        border-radius: 12px;
        color: #B2B4B5 !important;
      }
    }
  }
}

.bank-details--add {
  cursor: pointer;
  color: #2ab8f5;

  &:hover {
    text-decoration: underline;
  }
}