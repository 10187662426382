.app__container,
.mobileSidebar__container {
  display: flex;

  .ant-btn {
    box-shadow: none !important;

    .hamIcon {
      transform: scale(1.4);
    }
  }

  .nav__container {
    width: 250px;
    height: 100vh;
    min-width: fit-content;
    overflow-y: scroll;
  }

  .route__container {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }

  .route__container > *:not(.page-header__container) {
    animation: bloomIn 1000ms ease forwards;
  }

  .route__container::-webkit-scrollbar {
    width: 10px;
    background: white;
  }

  .route__container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(192, 192, 192);
  }

  .nav__container::-webkit-scrollbar,
  .route__container::-webkit-scrollbar-track,
  .route__container::-webkit-scrollbar-track-piece,
  .route__container::-webkit-scrollbar-button {
    display: none;
  }

  @media (max-width:960px){
    display: flex;
    flex-direction: column;
    .sidebar__notification{
    margin-top: -1.875rem;
    }
    .wallet__notification {
      width: 25px;
      height: 25px;
      cursor: pointer;
      position: relative;
      margin-right: 1.25rem;

      img {
        width: 100%;
        height: 100%;
      }
  
      &.badge::before {
        content: "\2022";
        color: hsla(9, 100%, 72%, 0.5);
        font-size: 350%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -50%);
      }
  
      &.badge::after {
        content: "\2022";
        color: #ff8872;
        font-size: 200%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(17%, -43%);
      }
    }

    .wallet__notifications-menu{
      overflow: auto;
      width: 20rem;
      z-index: 1000;
      border-radius: 0.7rem;
      padding: 1rem;
      background: #FFFFFF;
      position: absolute;
      right: 1.6rem;
      top: 3rem;
      
      border: 0.1rem solid #F1F9FE;
  
      &>.ant-menu-vertical {
        border: 0;
      }
      
      &~.menu-item {
        &:hover {
        background: #F1F9FE;
        }
      }
      
      &.navbar-user-menu {
        &>.ant-menu-vertical {
          border: 0;
          padding: 0;
        }
      }

      .wallet__notifications-header {
        padding: 0.6rem;
      
        .notifications-header__icon{
          margin-top: -0.75rem;
          width: 1.8125rem;
          height: 1.5625rem;
          cursor: pointer;
          position: relative;
      
          img {
            width: 100%;
            height: 100%;
          }
        }
        .notifications-header__text{
          font-weight: 600;
          font-size: 0.9375rem;
          text-shadow: 0.0063rem 0 #000000;
          margin-left: -0.4375rem;
        }
        .notifications-header__mark-all{
          p{
            cursor: pointer;
            font-weight: 800;
            font-size: 0.9375rem;
            line-height: 1.5715;
            color: #002f87;
            text-shadow: 0.0063rem 0 #002f87;
            margin-right: 0.625rem;
          }
        }
      }

      .wallet__notifications-menu__menu{
        height: 19rem;
        overflow: scroll;
      }

      div.ant-col.ant-col-3.wallet__notifications-menu__icon{
        display: flex;
        width: 1.3rem;
        height: 1.3rem;
        max-width: 2rem;
        margin-top: 1.25rem;
      }

      div.ant-row.wallet__notifications-menu__item-row{
        padding: 1rem;
        &span {
          line-height: 1;
        }

        .text--blue {
          color:  #002f87!important;
          white-space: pre-wrap;
        }
      
        .text--black {
          color: #000000 !important;
          white-space: pre-wrap;
        }

      }
      
      .ant-menu-item.ant-menu-item-only-child.wallet__notifications-menu__item{
          padding: 0.3rem 0.4rem 0 0.7rem;
          width: 100%;
          height: auto;
          line-height: 1.1875rem;
          &:hover{
            background:#FAFAFCF4;
        }
      }
      .notification__item-row-date{
        font-size: 0.75rem;
        span{
          margin-top: 0.3125rem;
        }
      }
    }
  
   }
}

@keyframes bloomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .app__container {
    display: block;
    .nav__container {
      display: none;
    }
  }
}

@media (main-width: 960px) {
  .mobileSidebar__container {
    display: none;
  }
}

