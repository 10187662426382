@import "src/styles/variables";

.profile-other-details {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
    background: #fff;
    box-shadow: 0px 30px 30px #43d5ae0d;
    border-radius: 30px;

    &:first-child {
        margin-top: 0;
    }

    .profile-other-details__header {
        margin: 0 0 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-btn {
            background: #30303017;
            border: 1px solid #303030;
            border-radius: 14px;
            color: #303030;

            &:focus,
            &:hover,
            &:active {
                color: #303030;
                border: 1px solid #303030;
            }
        }

        .profile-other-details--title {
            margin: 0;
            width: 100%;
            display: flex;
            font-size: 24px;
            font-weight: 500;
            align-items: center;

            .title-icon {
                margin: 0 1rem 0 0;
                padding: .5rem;
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                border-radius: 50px;
                text-align: center;

                &.mid-dark-yellow {
                    background: $mid-dark-yellow;
                }

                &.mid-dark-purple {
                    background: $mid-dark-purple;
                }

                &.mid-dark-green {
                    background: $mid-dark-green;
                }
            }
        }

        .profile-other-details--title {
            .title-icon {
                img {
                    width: 25px;
                }
            }
        }
    }

    .profile-other-details__body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;


        .profile-other-details--content {
            width: 50%;
            margin: 0.5rem 0;
            font-size: 18px;

            .profile-other-details--content-title {
                color: #b1b3b4;
                font-size: 16px;
            }
        }
    }

    .profile-other-details__empty-state {
        .profile-other-details--title {
            margin: 0;
            text-align: center;
            font-size: 24px;
            font-weight: 500;

            .title-icon {
                margin: 1rem auto;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;

                img {
                    width: 25px;
                }

                &.mid-dark-yellow {
                    background: $mid-dark-yellow;
                }

                &.mid-dark-purple {
                    background: $mid-dark-purple;
                }

                &.mid-dark-green {
                    background: $mid-dark-green;
                }
            }
        }

        .profile-other-details--empty-note {
            margin: 1rem auto;
            font-size: 16px;
            color: #707070;
            text-align: center;
        }

        .profile-other-details--empty-btn {
            margin: auto;
            height: 40px;
            color: #fff;
            font-size: 16px;
            display: block;
            background: #303030;
            border: 1px solid #303030;
            border-radius: 14px;
        }
    }
}