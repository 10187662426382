@import "src/styles/variables";

/* Button */
.ant-btn.ant-btn-primary {
	background-color: $primary-color;
	border-radius: 12px;
	border: none;
	width: 100%;
	height: 40px;
	box-shadow: 0px 3px 6px #00000029;

	&:disabled {
		color: white;
		opacity: 0.5;

		&:hover {
			background-color: $primary-color;
		}
	}
}

.ant-btn.ant-btn-text {
	background-color: white;
	border: 1px solid #b2b4b5;
	border-radius: 10px;
	height: 45px;
	width: 130px;
	color: $primary-black;
}


//  Radio button
.ant-radio-inner::after {
	background-color: #002F87;
}

.ant-radio-inner {
	border-color: #002F87 !important;
}

.ant-radio-group.ant-radio-group-outline {
	label {
		span {
			font-weight: bolder;
		}
	}


	.ant-radio-wrapper.ant-radio-wrapper-checked {
		.ant-radio-inner {
			border-color: $primary-black;

			&::after {
				background-color: $primary-black;
			}
		}
	}

	.ant-radio-input:focus {
		box-shadow: none !important;
		border-color: $primary-black !important;
	}

	.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus+.ant-radio-inner {
		border-color: $primary-black !important;
	}

	margin-bottom: 1rem;
}

.ant-radio-group.ant-radio-group-outline.primary-radio {
	.ant-radio-wrapper.ant-radio-wrapper-checked {
		.ant-radio-inner {
			border-color: $primary-color;

			&::after {
				background-color: $primary-color;
			}
		}
	}

	&.radio-color-text {
		.ant-radio-wrapper.ant-radio-wrapper-checked {
			span {
				color: $primary-color !important;
			}
		}
	}

	.ant-radio-input:focus {
		border-color: $primary-color !important;
	}

	.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus+.ant-radio-inner {
		border-color: $primary-color !important;
	}
}

// Stepper

.RFS-StepperContainer {
	padding-bottom: 1rem !important;
	.RFS-StepContainer {
		transition: margin-top 0.5s;

		button {
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}

		.active {
			.icon {
				color: red;
			}
		}

		.completed {
			.icon {
				color: yellowgreen;
			}
		}
	}

	.RFS-LabelContainer {
		display: none;
	}

	&:hover {
		.RFS-LabelContainer {
			display: block;
			animation: fade-in 1s;
		}

		.RFS-StepContainer {
			// animation: move-top 0.5s forwards;
			margin-top: -15px;
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// Dropdown

.ant-select {
	width: 100%;
	height: 40px;
	border-radius: 8px !important;
	margin-top: 0.5rem;

	.ant-select-selector {
		height: 40px !important;
		border-radius: 8px !important;
		border-color: $input-border !important;
	}

	&.ant-select-focused>.ant-select-selector {
		box-shadow: 0 0 0 1px $input-border !important;
	}

	.ant-select-selection-item {
		display: flex;
		align-items: center;
	}

	&-selection-placeholder {
		height: 100%;
		display: flex;
		align-items: center;
	}

	input {
		height: 40px;
	}
}

// Date Picker

.ant-picker {
	margin-top: 0.5rem;
	width: 100%;
	height: 40px;
	border-color: $date-picker-bg !important;
	background-color: $date-picker-bg;
	border-radius: 8px;

	&.ant-picker-focused {
		box-shadow: 0 0 0 1px $input-border !important;
	}
}

// Checkbox

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
	.ant-checkbox.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: $primary-color !important;
			border-color: $primary-color !important;
		}
	}

	span {
		color: $primary-color !important;
	}
}

/* Toottip */

.ant-tooltip-content {

	.ant-tooltip-inner {
		color: $primary-black;
		font-style: italic;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0px 3px 6px $primary-shadow;
	}

	.ant-tooltip-arrow {

		span {
			background-color: #fff;
		}
	}
}

/* Tabs */

.ant-tabs {

	.ant-tabs-nav {

		.ant-tabs-tab {
			font-size: 16px;
			color: #002e8580;

			&.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: #002F87;
					font-weight: 500;
				}
			}
		}

		.ant-tabs-ink-bar {
			height: 4px;
			background-color: #002F87;
		}

	}

	.ant-tabs-nav::before {
		border: 0;
	}
}

/* Table */
.ant-table-content {

	.ant-table-tbody {
		border-radius: 20px;

		.ant-table-row {
			height: 75px;

			.ant-table-cell {
				font-weight: 600;
				background-color: #fff;
				border-bottom-color: #e3e3e373;
			}

		}

		.ant-table-row:last-child {
			border-radius: 20px;
			box-shadow: 0px 30px 30px #1C416D0F;


			.ant-table-cell {
				border-bottom: 0;
			}
		}
	}
}

/* Skeleton */
.ant-skeleton {
	max-width: 200px;

	&.ant-skeleton-element {
		width: 100%;
	}

	.ant-skeleton-input {
		margin: 0.25rem 0;
		width: 100%;
		border-radius: 5px;
	}

	&.mini-skeleton {
		&.ant-skeleton-element {
			width: auto;
		}

		.ant-skeleton-input {
			width: 50px;
		}
	}

}