.mpesa-transfer-innstructions__container {
    margin: 1rem 0 1rem;
    padding: 1.5rem 1rem 0rem;
    border-radius: 12px;
    font-size: 15px;
    background-color: #F7F7F8;

    .mpesa-transfer-innstructions--text {
        margin: 0 1rem;
        padding: 0 0 1rem;
        color: #707070;
        border-bottom: 1px solid #B2B4B5;
    }

    .mpesa-transfer-innstructions {
        margin: 1rem 0 0;
        padding: 1rem 1.5rem 0;
        font-size: 16px;

        .ant-timeline-item-head {
            background: transparent;
        }


        .ant-timeline-item-tail {
            display: none;
        }

        .timeline-step {
            display: inline;
            font-size: 12px;
            padding: .25rem .5rem;
            border-radius: 100%;
            color: #2AB8F5;
            background-color: hsla(198, 91%, 56%, 0.2);

        }

        .text-primary {
            font-weight: 600;
        }

        .ant-timeline-item-last>.ant-timeline-item-content {
            min-height: 0;
        }

        .ant-timeline-item.ant-timeline-item-last {
            padding: 0 0 1rem;

        }
    }

    .mpesa-transfer-innstructions--info {
        padding: 0 0 1rem 1.5rem;
        font-size: 12px;
        font-style: italic;
        color: #707070;
    }
}