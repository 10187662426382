@import "src/styles/variables";

.phone-number {
  .react-tel-input {
    margin-top: 0.5rem;

    .flag-dropdown {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid $input-border;

      &:hover {
        background-color: #ffffff;
      }

      &.open .selected-flag,
      .selected-flag {
        background: unset;

        &:hover {
          background-color: unset;
        }
      }
    }

    input {
      height: 40px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $input-border;
    }
  }
}
