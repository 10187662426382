@import "src/styles/variables";

.customer-details {
  h1 {
    font-weight: bolder;
    font-size: 24px;
    text-transform: capitalize;
  }

  .details__wrapper {
    background-color: rgba($color: $light-white, $alpha: 0.1);
    padding: 1rem 2rem 0 2rem;
    border-radius: 10px;

    .ant-col.ant-col-12 {
      margin-bottom: .5rem;

      label {
        color: $primary-black;
        font-weight: bold;
      }

      p {
        margin: auto 0;
        color: $primary-black;
        font-weight: bolder;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    .ant-btn.ant-btn-primary {
      width: 70% !important;
      text-align: center;

      @media (max-width: 995px) {
        width: 100% !important;
      }
    }
  }

  .link {
    text-align: center;
    margin: 1rem 0;

    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}