.mobile-input-modal__container {
    .ant-modal-content {
        padding: 2rem;
        width: 580px !important;
        border-radius: 30px;

        .ant-modal-close {
            top: 1rem;
            right: 1rem;
        }

        .ant-modal-header {
            border-radius: 30px;
            border-bottom: none;
            .ant-modal-title {
                font-size: 24px;
                font-weight: bold;
            }
        }

        .ant-modal-body {
            .input-field__wrapper {
                margin: .5rem 0 1rem;
                .ant-input {
                    height: 52px;
                }
            }

            .styled-modal-btn {
                margin: 2rem auto 0 auto;
                width: fit-content;

                .ant-btn-primary {
                    width: 300px;
                    height: 48px;
display: flex;
align-items: center;
justify-content: center;
                    border-radius: 18px;
                    border-top: 3px solid #2AB8F5;
                    background: #002F87;

                    .icon-message::before{
                        margin: 0 1rem;
                        font-size: 18px;
                    }
                }
            }
        }

        .ant-modal-footer {
            display: none;
        }
    }
}