@import "src/styles/variables";

.customer-form {
  width: 70%;
  padding: 12px;
  @media (max-width: 995px) {
    width: 100%;
  }
  h1 {
    font-weight: bolder;
  }

  .title {
    display: block;
    margin-bottom: 0.5rem;
  }
  form {
    margin-top: 2.5rem;

    .submit-btn {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      .ant-btn.ant-btn-primary {
        width: 50% !important;
        text-align: center;
      }
    }
  }

  .move-up {
    animation: move-up 0.5s;
  }
}

@keyframes move-up {
  from {
    opacity: 0;
    margin-top: 10vh;
  }

  to {
    opacity: 1;
    margin-top: 0vh;
  }
}
