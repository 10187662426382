.bank-transfer-innstructions__container {
    margin: 1rem 0 1rem;
    padding: 1.5rem 1rem 1rem;
    border-radius: 12px;
    font-size: 15px;
    background-color: #F7F7F8;

    .bank-transfer-innstructions--text {
        margin: 0 1rem;
        padding: 0 0 1rem;
        font-size: 14px;
        color: #707070;
        border-bottom: 1px solid #B2B4B5;
    }

    .bank-transfer-innstructions--list {
        // margin: auto 2rem;

        p {
            margin: 1rem 1rem 0;

            .title,
            .value {
                display: inline-block;
                width: 50%;
                font-size: 14px;
            }

            .value {
                color: #002F87;
                font-weight: 600;
            }
        }
    }

    .bank-transfer-innstructions--info {
        margin: 1rem 1rem .5rem;
        font-size: 12px;
        font-style: italic;
        color: #707070;
    }
}