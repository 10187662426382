@import "src/styles/variables";

.timeline {
	height: 16vh;
	// margin-bottom: .75rem;
	i {
		color: rgba($color: #b2b4b5, $alpha: 0.5);
		font-size: 20px;
	}

	.active {
		i {
			color: #b2b4b5;
		}
	}
	.completed {
		i {
			color: #a4cf57;
		}
	}
}
