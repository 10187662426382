.alert-modal__container {
  .ant-modal-content {
    padding: 1rem;
    border-radius: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 30px #1c416d0f;

    .ant-modal-header {
      padding-bottom: 0;
      border-radius: 30px;
      border-bottom: none;

      .ant-modal-title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .ant-modal-body {
      padding: 1rem 24px;
    }

    .ant-modal-footer {
      padding-bottom: 1rem;
      border: none;

      .ant-btn:not(.ant-btn-primary) {
        display: none;
      }
    }

    .ant-modal-close {
      top: 1rem;

      .ant-modal-close-x {
        font-size: 12px;
      }
    }
  }

  &.mini-modal {
    .ant-modal-content {
      padding: 3rem 1rem 1rem;
      text-align: center;

      .ant-modal-body {
        font-size: 18px;
        padding-bottom: 2rem;
      }

      .ant-modal-close {
        display: none;
      }
    }
  }
}