.ant-modal.success-modal {
    width: 550px !important;
    top: 10% !important;
    box-shadow: 0px 30px 30px #1C416D0F;

    .ant-modal-content {
        border-radius: 30px;

        .ant-modal-close {
            top: 1.5rem;
            right: 1rem;

            .ant-modal-close-x {

                .anticon {
                    color: #303030;
                    font-size: 12px;
                }
            }
        }

        .ant-modal-header {
            padding: 2.5rem 2.5rem 1rem 2.5rem;
            border: 0;
            border-radius: 30px;

            .ant-modal-title {
                font-size: 24px;
                font-weight: bold;
                color: #303030;
            }
        }

        .ant-modal-body {
            padding: 0 2.5rem 160px 2.5rem;
            position: relative;
            background-image: url("./../../../assets/images/buy-success.svg");
            background-size: 111px 111px;
            background-position: center bottom;
            background-repeat: no-repeat;

            p{
                margin: 0;
                font-size: 16px;
            }
        }

        .ant-modal-footer{
            padding: 2rem 2.5rem;
            border: none;
    
            .ant-btn-primary{
                margin: 0;
                background-color: #303030;
                border-radius: 18px;
                height: 48px;
    
                span{
                    font-weight: 600;
                    font-size: 17px;
                }
            }

            .ant-btn:not(.ant-btn-primary){
                display: none;
            }
        }
    }
}

.ant-modal.success-modal.buy {
    .ant-modal-body {
        background-image: url("./../../../assets/images/buy-success.svg");
    }
}
.ant-modal.success-modal.sell {
    .ant-modal-body {
        background-image: url("./../../../assets/images/sell-succss.svg");
    }
}