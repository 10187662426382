.ui-modal.ant-modal {
    top: 1rem !important;
    padding-bottom: 1rem;

    .ant-modal-content {
        box-shadow: 0px 30px 30px #1C416D0F;
        border-radius: 30px;

        .ant-modal-close {
            top: 1rem;
            right: 1rem;

            .ant-modal-close-x {
                font-size: 14px;
            }
        }

        .ant-modal-header {
            background: transparent;
            padding: 2rem 2rem 1rem;
            border-bottom: none;

            .ant-modal-title {
                font-size: 24px;
                font-weight: bold;
            }
        }

        .ant-modal-body {
            padding: 1rem 2rem 0;

            // .internal-user-form__container {}

        }

        .ant-modal-footer {
            padding-bottom: 2rem;
            border-top: none;
            text-align: center;

            .ant-btn {
                margin: 0;
                width: 200px;
                border-top: 2px solid #2AB8F5;
            }

            .ant-btn:not(.ant-btn-primary) {
                display: none;
            }
        }
    }
}