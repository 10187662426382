.pending-documents__container {
  .pending-document {
    margin: 1rem auto;
    padding: 0 2rem;
    width: 550px;
    background: #b1b3b423;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pending-document--name {
      .title {
        margin: 1rem 0 0.5rem;
        text-align: start;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
      }

      .reason {
        font-size: 15px;
        text-align: start;
      }
    }
  }

  .video-recorder {
    position: absolute;
    top: 0;
  }
}

.ant-modal.footer-header-less {
  .ant-modal-content {
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
  }
}
