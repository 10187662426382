@import "src/styles/variables";

.upload-documents {

    .title__charma {
        font-weight: bolder;
        font-size: 16px;
    }

    h1 {
        font-weight: bolder;
        margin-bottom: 0;
    }

    .constrain {
        color: $primary-black;
        font-size: 16px;
        text-align: center;
    }

    .submit-btn {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        .ant-btn.ant-btn-primary {
            width: 70% !important;
            text-align: center;
        }
    }
}

@media (max-width: 995px) {
    .upload-documents {
        margin-bottom: 1.5rem;
    }
}