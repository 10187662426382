.loader__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
        width: 50px !important;
        height: 50px !important;
    }
}

@keyframes rotateLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

}