@import "src/styles/variables";

.account-details-form {
  width: 70%;
  margin-left: 12px;

  @media (max-width: 1260px) {
    width: 90%;
  }
  h1 {
    font-weight: bolder;
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    .ant-btn.ant-btn-primary {
      width: 50% !important;
      text-align: center;
    }
  }

  .login-link {
    &:hover {
      text-decoration: underline;
    }
  }
}
