@import "src/styles/variables";

.input-field__wrapper {
  &.mb-0 {
    margin-bottom: 0 !important;
  }

  .ant-input-group-wrapper {
    // margin-top: 0.5rem;
  }

  input {
    border: 1px solid $input-border;
    border-radius: 8px;
    height: 40px;
    text-indent: 8px;

    &:hover,
    &:focus {
      border-color: $input-border;
    }

    &:focus {
      box-shadow: 0 0 0 1px $input-border;
    }

    &:disabled {
      border: none;
      background-color: #e9e9e9;
      color: black;
    }
  }

  .ant-input-group-addon {
    background: white !important;
    border-color: $input-border;
    border-radius: 8px;

    .ant-dropdown-trigger {
      color: $primary-black;
    }
  }
}

.no-border-right {
  input {
    border-left: none;

    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-group-addon {
    border-right: none !important;
    padding-right: 5px;
  }
}

div.mpesa-field {
  position: relative;

  &:before {
    content: "+254";
    color: #303030;
    margin: 0.5rem 1rem;
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
  }

  &.extra-label {
    &:before {
      margin: 2rem 1rem;
    }
  }

  &.lg {
    &:before {
      margin: 0.85rem 1rem;
    }
  }
}

input.mpesa-field {
  margin-top: 0 !important;
  padding: 0 0 0 4rem;
}