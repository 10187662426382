@import "src/styles/variables";

.video-k-y-c {
    position: relative;
}


@media (max-width: 995px) {
    .video-k-y-c {
        margin-bottom: 1.5rem;
    }
}