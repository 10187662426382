@import "src/styles/variables";

.question {
  padding: 3.5rem;
  animation: fade-in-top 1s;
  @media (max-width: 995px) {
    padding: 1.5rem;
  }

  h1 {
    font-weight: bolder;
  }

  .space {
    margin-top: 0.5rem;
    width: 100%;
    height: 350px;
    overflow-y: scroll;
    .ant-space-item {
      margin-top: 1rem;
      span {
        font-size: 16px;
        font-weight: lighter;
        color: $primary-black;
        white-space: normal !important;
      }
    }
  }
  .space::-webkit-scrollbar {
    display: none;
  }
}
