.payment-form {
  margin: auto;
  max-width: 450px;

  h1 {
    font-weight: bolder;
  }

  .ant-divider-horizontal {
    margin: 0;
  }

  .payment-form__payment-method {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 16px;

    &.justify-between {
      margin: 0;
      justify-content: space-between;
    }

    p {
      margin: 1rem 1rem 1rem 0;
    }

    .m-pesa-icon {
      width: 65px;
      height: 25px;
    }

    .bank-icon__container {
      padding: 0.4rem 0.4rem 0.4rem 0.5rem;
      border: 0;
      border-radius: 100%;
      background-color: #ffa31a31;
      text-align: center;

      img {
        width: 80%;
      }
    }
  }

  .payment-form__mobile-form {
    .payment-form__mobile-form--split-up {
      margin: 0 auto 5rem;

      .danger {
        color: #ff6d3f !important;
      }
    }

    .pay-btn {
      button {
        background-color: #303030;
      }

      .pay-btn-wallet {
        button {
          background-color: #316fe1;
        }
      }

      span {
        font-weight: 600;
      }
    }
  }

  .payment_form__footer {
    margin: 1rem auto;
    width: fit-content;

    img {
      margin: 0 0.5rem;
      width: 15px;
      height: 15px;
    }
  }
}

.terms-acceptance-label {
  margin: 0 0.5rem;
  color: #000 !important;
  cursor: pointer;

  b {
    color: #316fe1 !important;
  }
}

.terms-acceptance--checkbox {
  // .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
  // .ant-checkbox-wrapper{

  // }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    .ant-checkbox.ant-checkbox-checked {

      .ant-checkbox-inner,
      .ant-checkbox-input {
        background-color: #43d5ae !important;
        border-color: #43d5ae !important;
        color: #43d5ae !important;
      }
    }
  }
}

.ant-checkbox.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #43d5ae !important;
    border-color: #43d5ae !important;
    color: #43d5ae !important;
  }
}

.payment-form__mobile-form--split-up {
  margin: 1rem auto;
  width: 80%;

  li {
    font-size: 15px;
  }

  .price {
    color: #000;
  }

  .price-bold {
    font-weight: bold;
  }
}

@media (max-width: 995px) {
  .payment-form {
    padding: 1rem 0 1rem 1.5rem;
  }
}