.iprs-details {
  margin: 0 1rem;
  padding: 2rem;
  width: 100%;
  background: #002f87;
  box-shadow: 0px 30px 30px #43d5ae0d;
  border-top: 3px solid #2ab8f5;
  border-radius: 30px;
  color: #fff;
  @media (max-width: 960px) {
    width: 100%;
    margin: auto;
  }

  .iprs-details__header {
    .iprs-details--title {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      text-transform: capitalize;

      &.reduced-opacity {
        opacity: 0.1;
      }
    }

    .iprs-details--mail,
    .iprs-details--phone {
      margin: 0.5rem 0;
      color: #2ab8f5;
      font-size: 20px;

      img {
        margin: 0 0.5rem 0 0;
        width: 15px;
      }
    }
  }

  .iprs-details__body {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    .iprs-details--content {
      width: calc(50% - 1rem);
      margin: 0.5rem 0.5rem 0.5rem 0;
      font-size: 18px;
      text-transform: capitalize;

      .iprs-details--content-title {
        color: #b1b3b471;
        font-size: 16px;
      }
    }
  }

  .iprs-details__empty {
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffa417;
    font-size: 14px;

    p {
      margin: 0.5rem 0;
    }

    .icon--container {
      width: 50px;
      height: 50px;
      font-size: 18px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: hsla(36, 100%, 55%, 0.2);
    }

    .modal-trigger {
      color: #fff;
      font-size: 16px;
      margin: 0 0 0.5rem 0;
    }
  }
}

@media (max-width: 960px) {
  .iprs-details {
    width: 100%;
    margin: auto;
  }
}
