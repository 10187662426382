@import "src/styles/variables";

.document-upload {
	.error-message {
		margin-top: 0.75rem;
	}

	.make-heavy {
		font-size: 18px;
		font-weight: bolder;
	}

	.drag-and-drop__container {
		margin-top: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px dashed #002f87;
		border-radius: 16px;
		background-color: #f6f6f6;
		padding: 1rem;
		cursor: pointer;

		p {
			color: #b2b4b5;
			margin: auto 0;
		}
	}

	button {
		background-color: #b2b4b5;
		border-radius: 14px;
		opacity: 0.7;
		color: black;
		font-weight: bolder;
		// &:hover {
		// 	background-color: #b2b4b5;
		// 	color: black;
		// }
	}

	.drag-and-drop__display {
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 5px 10px #0000000d;
		border-radius: 12px;
		padding: 2rem;

		.content {
			img {
				width: 30px;
				height: 30px;
			}
		}

		span {
			color: #B2B4B5;
			cursor: pointer;
		}
	}
}