.sidebar__container,
.mySidebar__container {
  margin-top: 50px;
  height: calc(100% - 50px);

  .sidebar__logo {
    width: 100%;
    height: 75px;
    background: url("./../../../assets/icons/logo\ -\ svg/vuka-logo-dark.svg")
      no-repeat top center;
    background-size: 80px 25px;
  }

  .sidebar__items {
    padding: 0 1rem;
    width: 100%;
    height: fit-content;
    min-height: calc(100% - 125px);
    border-right: 1px solid silver;
    position: relative;

    .sidebar__items--item {
      margin: 0 auto 1rem auto;
      padding: 0 0 0 2.5rem;
      width: 200px;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 15px;
      color: #002f87;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      transition: all 500ms;

      img {
        margin: 0 0.5rem 0 0;
        width: 20px;
      }

      &.ant-btn {
        border: none;

        &::after,
        &::before {
          display: none;
        }
      }
    }

    .sidebar__items--item:nth-child(1).active ~ .sidebar__items--item.animator {
      top: 0px;
    }

    .sidebar__items--item:nth-child(2).active ~ .sidebar__items--item.animator {
      top: calc(1 * 66px);
    }

    .sidebar__items--item:nth-child(3).active ~ .sidebar__items--item.animator {
      top: calc(2 * 66px);
    }

    .sidebar__items--item:nth-child(4).active ~ .sidebar__items--item.animator {
      top: calc(3 * 66px);
    }

    .sidebar__items--item:nth-child(5).active ~ .sidebar__items--item.animator {
      top: calc(4 * 66px);
    }

    .sidebar__items--item:nth-child(6).active ~ .sidebar__items--item.animator {
      top: calc(5 * 66px);
    }

    .sidebar__items--item:nth-child(7).active ~ .sidebar__items--item.animator {
      top: calc(6 * 66px);
    }

    .sidebar__items--item:nth-child(8).active ~ .sidebar__items--item.animator {
      top: calc(7 * 66px);
    }

    .sidebar__items--item.animator {
      position: absolute;
      top: 0;
      z-index: -1;
      background: #002f87;
    }

    .sidebar__items--item.active {
      color: #fff;
    }

    .sidebar__items--item.disabled {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

@media (max-width: 960px) {
  .mobileSidebar__container {
    background-color: white;
    height: 60px;
    background: url("./../../../assets/icons/logo\ -\ svg/vuka-logo-dark.svg")
      no-repeat top center;
    background-size: 95px 35px;
    margin-top: 10px;
    position: relative;
    button {
      color: black;
      height: 35px !important;
      margin: 2px;
      z-index: 10;
      width: 12% !important;
      background-color: transparent !important;
      border-radius: 10px !important;
    }
  }
}

@media (min-width: 960px) {
  .mobileSidebar__container {
    display: none;
  }
}

@media (max-width: 960px) {
  .sidebar__container,
  .mySidebar__container {
    padding: 0;
    margin: 0;
    border: none;

    .sidebar__items {
      padding: 0 !important;
      border: none;
    }
  }
  .sidebar__items--item {
    font-size: 1.1rem !important;
  }
}
