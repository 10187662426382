@import "src/styles/variables";

.faq--container {
  padding-bottom: 3rem;

  .ant-collapse {
    margin: 0 2rem;
    background-color: transparent;
    @media (max-width: 960px) {
      margin: 0;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        padding: 1.5rem;

        .anticon {
          font-size: 18px !important;
          font-weight: bold;
          color: $link-color;
        }

        font-size: 20px;
        font-weight: bold;
      }

      .ant-collapse-content {
        margin-left: 30px;
        font-size: 16px;

        .ant-collapse-content-box > div {
          border-left: 2px solid #2ab8f548;
          padding-left: 1.5rem;
        }
      }
    }
  }
}
