@import "src/styles/variables";

.risk-assesment-questions {
  position: fixed;
  height: fit-content;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;

  .header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 2rem;
    background-color: $primary-color;

    @media (max-width: 995px) {
      padding: 0.5rem 0.8rem;
    }

    img {
      height: 1.75rem;

      @media (max-width: 995px) {
        height: 1.7rem;
      }
    }

    h1 {
      color: white;
      padding: 0px;
      margin: 0px;

      @media (max-width: 995px) {
        margin-top: 7px;
        font-size: 24px;
      }

      @media (max-width: 370px) {
        /*  font-size: 24px; */
        font-size: 22px;
      }
    }

    .anticon.anticon-close {
      font-size: 24px;
      color: white;
      margin: auto 0;
    }
  }

  .question-controller {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 6vh;
    right: 4vw;
    animation: fade-in-top 1s;

    @media (max-width: 995px) {
      bottom: 10vh;
    }

    .icon {
      cursor: pointer;
      padding: 5px 12px;
      border-radius: 100%;
      background-color: rgba($color: $primary-color, $alpha: 0.1);

      &:first-child {
        margin-right: 12px;
      }
    }

    p {
      margin: auto 12px auto 0;
    }
  }

  .risk-assesment__submit {
    position: absolute;
    bottom: 6vh;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    animation: fade-in-top 1s;

    @media (max-width: 995px) {
      bottom: 10vh;
    }

    button {
      width: 150px;
    }
  }
}

@keyframes fade-in-top {
  from {
    opacity: 0;
    bottom: 0vh;
  }

  to {
    opacity: 1;
    bottom: 6vh;
  }
}