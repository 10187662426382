@import "src/styles/variables";

.customer-type {
	padding: 1.5rem 0px;

	h1 {
		font-weight: bolder;
	}

	.customer-type__wrapper {
		display: flex;
		justify-content: center;
		margin-top: 5rem;

		.card {
			box-shadow: 0px 10px 20px #1c416d0d;
			border-radius: 30px;
			padding: 2.2rem 2rem;
			position: relative;

			p {
				margin: auto;
				position: absolute;
				bottom: 12px;
				left: 35%;
			}

			&:first-child {
				margin-right: 24px;

				img {
					width: 100px;
				}
			}

			&:last-child {
				margin-right: 24px;

				img {
					margin-bottom: 1rem;
					width: 100px;
				}
			}
		}

		.card-individual {
			background: #FFD12E;
		}

		.card-chama {
			background: #2AB8F5;
		}
	}

	.submit-btn {
		display: flex;
		justify-content: center;
		margin-top: 3rem;

		.ant-btn.ant-btn-primary {
			width: 35% !important;
			text-align: center;
		}
	}
}