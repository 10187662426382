.restricted-access__container {
  color: #303030;

  @media (max-width: 960px) {
    .page-header__container {
      .page-header__details {
        .page-header__details--title {
          margin: 0 3rem 0 0.6rem;
          padding-bottom: 2.2rem;

          .page-header__details--title-chip {
            margin: 1rem 0 0 0;
            padding: 0.6rem 1.2rem;
            text-align: center;
          }
        }
      }
    }
  }

  .restricted-access--text {
    margin: -2rem 3rem 0;
    font-size: 18px;
  }

  .restricted-access__body-pending {
    margin: 8rem 3rem 3rem;
    text-align: center;

    img {
      width: 100%;
      max-width: 830px;
      // height: 300px;
    }

    p {
      margin: 1rem auto;
      font-size: 20px;
      font-weight: lighter;
    }
  }

  .restricted-access__body-rejected {
    margin: 2rem auto;
    text-align: center;
    width: 50%;
    min-width: 500px;


    img {
      width: 100%;
    }

    .rejected-text {
      margin: 1rem 0 0rem;
      font-size: 20px;
      font-weight: 500;
      font-style: italic;
      color: #ff8872;

      img {
        margin: 0 0.5rem;
        width: 20px;
      }
    }

    .text {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 960px) {
  .restricted-access__body-rejected {
    width: 90% !important;
    margin: auto;
    min-width: unset !important;

  }

  .pending-documents__container {
    .pending-document {
      width: 100% !important;
    }
  }
}