@import "src/styles/variables";

.video-instructions {
  h1 {
    font-weight: bolder;
  }

  .instruction-title {
    color: $primary-black;
    font-weight: bold;
  }

  .slick-slider {
    margin-top: 2.5rem !important;
  }

  .slider-image__container {
    display: flex;
    justify-content: center;
    img {
      margin: auto;
    }
  }

  .instructions {
    color: $primary-black;
    margin-left: 1.5rem;
    @media (max-width: 995px) {
      margin-left: 0;
      text-align: justify;
      padding: 0;
    }

    li {
      list-style: none;
      &::before {
        content: "\2022";
        color: #2ab8f5;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        height: 5px;
      }
    }
  }

  .slick-dots > li > button:before {
    font-size: 10px !important;
    opacity: 1 !important;
    color: white !important;
    border: 1px solid $primary-color;
    border-radius: 100%;
    line-height: 12px !important;
    height: 12px !important;
    width: 12px !important;
  }

  .slick-dots > li.slick-active > button:before {
    color: $primary-color !important;
    font-size: 12px !important;
    border: none !important;
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    .ant-btn.ant-btn-primary {
      width: 40% !important;
      text-align: center;
      @media (max-width: 995px) {
        width: 100% !important;
      }
    }
  }
}
