.alert-modal__container.payment-modal {

    .ant-modal-content {
        padding: 0;

        .ant-modal-body {
            padding: 0;

            .payment-modal__container {
                text-align: center;
                border-radius: 30px;
                box-shadow: 0px 0px 10px silver;

                .payment-modal__container--body {
                    padding: 2rem 2rem 3rem;
                    border-top-left-radius: 30px;
                    border-top-right-radius: 30px;

                    h2 {
                        margin-bottom: 1rem;
                        text-align: center;
                        font-size: 20px;
                    }

                    .payment-processing-icon {
                        width: 80px;
                        margin: 2rem auto;
                    }
                }

                .payment-modal__container--footer {
                    border-bottom-right-radius: 30px;
                    border-bottom-left-radius: 30px;

                    .cancel-payment {
                        padding: 1rem 0;
                        width: 100%;
                        height: 70px;
                        color: #002F87;
                        border: none;
                        border-bottom-right-radius: 30px;
                        border-bottom-left-radius: 30px;
                        border-top: 1px solid rgba(192, 192, 192, 0.39);

                        span {
                            font-weight: 600;
                        }
                    }
                }

                .logo {
                    width: 180px;
                    height: 65px;
                    position: absolute;
                    bottom: calc(334px - 80vh);
                    transform: translate(-50%, 0px);
                    z-index: -5;
                }

            }
        }

        .ant-modal-footer {
            display: none;
        }
    }
}