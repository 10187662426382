.holdings__container {
  .holdings {
    max-width: 100%;
    margin: 0 2rem 2rem 2rem;
    padding: 2rem;
    height: fit-content;
    min-height: 120px;
    background-color: #002f87;
    border-radius: 30px;
    display: flex;
    align-items: flex-start;
    /*  flex-wrap: wrap; */
    border-top: 3px solid #2ab8f5;
    box-shadow: 0px 30px 30px #1c416d1a;
    justify-content: space-between;

    @media (max-width: 960px) {
      margin: 0 1rem;
    }

    .holdings-data {
      padding: 0 0 0 2%;
      min-width: 100px;

      @media (max-width: 1100px) {}

      .holdings-data--header-title {
        color: #ffffffcc;
        font-size: 15px;
        white-space: nowrap;

        @media (min-width: 1200px) {
          white-space: nowrap !important;
        }
      }

      @media (max-width: 960px) {
        .holdings-data--header-title {
          padding-top: 1rem;
        }
      }

      .holdings-data--header-value {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .holdings__data {
    margin: 2rem 0;
    font-size: 16px;

    @media (max-width: 960px) {
      margin: 3rem 0;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-tabs {
      .ant-tabs-nav-wrap {
        @media (max-width: 960px) {
          margin-left: 15px;
        }
      }

      @media (max-width: 960px) {
        margin: -30px;
      }
    }

    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        margin: 0 3rem 2rem 3rem;
      }

      .ant-tabs-content-holder {
        margin: 0 3rem 3rem 3rem;

        @media (max-width: 960px) {
          overflow: auto !important;
        }
      }
    }
  }
  .holdings__auto-dividend--container{
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    padding-bottom: 1rem;
    margin-top: -4.375rem;

    .holdings__auto-dividend--heading{
      font-weight: bold;
      font-size: 1rem;
    }
    .ant-switch{
      min-width: 38px;
    }
    .ant-switch-checked{
      background-color: #43D5AE;
    }
  }

  @media (max-width: 450px){
    .holdings__auto-dividend--container{
      margin: 0 0 0 1rem;
      display: flex;
      justify-content: flex-start;

    }
    .holdings__container .holdings__data .ant-tabs.ant-tabs-top .ant-tabs-nav{
      margin: 0 3rem 1rem 3rem;
    }
  }
}

.Purchased {
  color: #a4cf57;
}

.Pending,
.pending,
.Hold {
  color: #ffa417;
}

.Fulfilled,
.Approved,
.Partially {
  color: #43d5ae;
}

.rejected,
.Rejected,
.Failure,
.Cancelled {
  color: #e4897b;
}

@media (max-width: 960px) {

  .holding-return-value,
  .holding-return-title {
    position: relative;
    right: 50%;
  }
}

@media (max-width: 960px) {
  .holdings {
    .holdings-data {
      text-align: center;
      padding: 5% 0;
      width: 100% !important;
      margin: auto;
      font-size: 20px !important;

      .holdings-data--header-title {
        font-size: 20px;
      }
    }
  }

  .holdings__data {
    .ant-tabs-content-holder {
      overflow: auto !important;
    }
  }
}

@media (max-width: 320px) {
  .holdings-data--header-title {
    font-size: 0.875rem !important;
  }

  .holdings-data--header-value {
    font-size: 0.875rem !important;
  }
}