@import "./../../../styles/variables";

.table__container {
    .ant-table-wrapper {
        .ant-table {
            .ant-table-container {
                .ant-table-content {
                    font-size: 16px;

                    .ant-table-thead {
                        background-color: #002F87;
                        border-radius: 20px 0 20px 0;

                        tr {
                            th {
                                color: white;
                                background: transparent;
                            }

                            th:first-child {
                                border-top-left-radius: 20px;
                            }

                            th:last-child {
                                border-top-right-radius: 20px;
                            }
                        }

                    }

                    .ant-table-tbody {

                        tr:last-child {
                            border-radius: 0 0 20px 20px;

                            td:first-child {
                                border-radius: 0 0 0 20px;
                            }

                            td:last-child {
                                border-radius: 0 0 20px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table__container.holdings-table {
    .ant-table-wrapper {
        .ant-table {
            .ant-table-container {
                .ant-table-content {

                    .ant-table-thead,
                    .ant-table-tbody {

                        .ant-table-cell {
                            width: calc(100% / 5);
                            padding: 1rem 1rem 1rem 3rem;
                        }

                    }
                }
            }
        }
    }

}


.ant-pagination.ant-table-pagination {

    .ant-pagination-total-text {
        position: absolute;
        left: 0;

        p {
            font-size: 14px;
            margin: 0;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        margin: 0;
        min-width: 24px;
        height: 24px;

        .ant-pagination-item-link {
            min-width: 24px;
            height: 24px;
            border: 0;
            line-height: 25px;

            .anticon {
                color: $primary-color;
            }
        }
    }

    .ant-pagination-item {
        margin: 0;
        min-width: 24px;
        height: 24px;
        border: none;

        a {
            margin: 0;
            min-width: 24px;
            height: 24px;
            line-height: 25px;
            border-radius: 6px;
            background-color: #fff;
            color: #000;

            &:hover {
                color: #000;
            }
        }


        &.ant-pagination-item-active {

            a {
                background-color: $primary-color;
                color: #fff;
            }
        }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        &:hover {

            .ant-pagination-item-link {
                .ant-pagination-item-container {

                    .ant-pagination-item-ellipsis {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ant-pagination-options {
        .ant-select.ant-pagination-options-size-changer {
            display: none;

        }
    }
}

.amount-column {
    padding-right: 5% !important;
}