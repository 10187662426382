@import "src/styles/variables";

.o-t-p-form {
  width: 80%;
  @media (max-width: 995px) {
    width: 100%;
  }
  h1 {
    font-weight: bolder;
    @media (max-width: 995px) {
      width: 100%;
    }
    @media (max-width: 360px) {
      font-size: 1.5rem;
    }
  }

  .otp__container {
    margin-top: 2.5rem;

    .resend__wrapper {
      margin-top: 1rem;
      display: flex;
      color: #b2b4b5;
      justify-content: flex-end;

      &.disable-resend {
        cursor: not-allowed;
      }
    }
  }

  .otp__wrapper {
    margin-top: 2rem;
    input {
      width: 3rem !important;
      border: none;
      border-bottom: 1px solid #b2b4b5;

      &:focus-visible {
        outline: none !important;
      }
    }
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    .ant-btn.ant-btn-primary {
      width: 50% !important;
      text-align: center;

      @media (max-width: 955px) {
        width: 100% !important;
      }
    }
  }
}
