.refer__container {
  margin: 0 auto 1rem;
  padding: 300px 0 0 0;
  background-image: url("./../../../assets/images/refer.png");

  background-size: 700px 290px;
  background-position: center top;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    background-size: 600px 250px;
  }

  .refer__container--email {
    margin: auto;
    padding: 0 1rem 1rem;
    width: 520px;
    height: 200px;
    border: none;
    text-align: center;
    position: relative;
    border-radius: 30px;
    background-color: white;

    @media (max-width: 960px) {
      width: 100%;
      text-align: center;
    }

    .refer__container--email-title {
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      color: #2ab8f5;
    }

    .refer__container--email-text {
      margin: 0;
      font-size: 16px;
      color: #002f87;
      padding-top: 1rem;
    }

    .refer__container--email__input {
      margin: 1.5rem auto 0;
      padding: 1rem 1rem 3rem;
      text-align: center;
      position: relative;
      border-radius: 30px;
      background-color: white;
      box-shadow: 0px 30px 30px #1c416d0f;

      @media (max-width: 960px) {
        width: 100%;
      }
    }

    .refer__container--email-field {
      margin: 1rem 0 0 0;
      width: 100%;
      height: 40px;
      border: 0;
      position: relative;
      font-size: clamp(22px, 32px, 40px);
      font-weight: 600;
      text-align: center;
      background-color: transparent;

      &::placeholder {
        color: #b2b4b5;
        opacity: 0.4;
      }

      &:focus {
        outline: transparent;
      }
    }

    .refer__container--email-button {
      width: 300px;
      height: 50px;
      font-size: 17px;
      font-weight: 600;
      border-radius: 18px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }

  .refer__container--instructions {
    margin: auto;
    padding: 5rem 0.5rem 0.5rem 0.5rem;
    width: 520px;
    color: #303030;
    @media (max-width: 960px) {
      width: 100%;
      margin-top: 5rem;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      @media (max-width: 960px) {
        margin-left: 1rem;
        font-size: 20px;
      }
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        margin-left: 3rem;
        font-size: 18px;
        /*  white-space: nowrap; */
        @media (max-width: 960px) {
          margin-left: 2rem;
          font-size: 16px;
        }

        span {
          display: inline-block;
          transform: translateY(-3px);
          display: inline;
        }

        &::before {
          content: "\2022";
          margin: 0 0.5rem 0 0;
          font-size: 175%;
          width: 8px;
          height: 5px;
          color: #2ab8f5;
          border-radius: 10px;
          margin-left: -27px;
        }
      }
    }
  }
}
