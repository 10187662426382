@import "src/styles/variables";

.bank-detail-from {
	animation: slide-top 1s;
	margin-top: 0vh;
	h1 {
		font-weight: bolder;
	}

	.submit-btn {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
		.ant-btn.ant-btn-primary {
			width: 70% !important;
			text-align: center;
		}
	}
}


@keyframes animation {
	from {
		margin-top: 10vh;
		opacity: 0;
	}

	to {
		margin-top: 0vh;
		opacity: 1;
	}
}