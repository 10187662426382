@import "src/styles/variables";

.account-manager {
  &__container {
    &--icon-container {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      &--icon-box {
        padding: 15px;
        background-color: #e9f9ff;
        border-radius: 50%;
        width: 56px;
        height: 56px;
      }
    }
    &--info-bar {
      padding: 32px 40px;
      box-shadow: 0px 10px 20px $lightest-grey;
      background-color: $white;
      border: 1px solid $lighter-grey;
      border-radius: 30px;
      margin-left: 40px;
    }
    &--info {
      &--key {
        font-size: 16px;
        color: $grey;

        &--name {
          color: $blue;
          font-size: 16px;
        }
      }

      &--value {
        font-size: 18px;
        color: $dark-black;
        margin-top: 16px;

        &--name {
          font-size: 24px;
          font-weight: 600;
          margin-top: 8px;
          color: $dark-black;
        }
      }
    }
  }
}
