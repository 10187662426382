@import "src/styles/variables";

.video-recorder {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  .header {
    position: absolute;
    z-index: 1;
    background-color: rgba($color: white, $alpha: 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 0.7rem 2rem;

    @media (max-width: 995px) {
      padding: 0.7rem 1rem;
    }

    p {
      margin: auto 0;
      font-size: 20px;

      @media (max-width: 995px) {
        position: relative;
        right: 6%;
        padding-top: 3px;
      }
    }

    .logo {
      height: 2.5rem;

      @media (max-width: 995px) {
        height: 1.7rem;
      }
    }
  }

  .video-controller__wrapper {
    display: flex;
    justify-content: center;

    .video-icon {
      font-size: 4rem;
      position: absolute;
      top: -45px;
      cursor: pointer;
    }
  }

  .kyc-video__controller {
    display: flex;
    margin-top: -20px;

    .submit-kyc {
      border-radius: 10px;
      height: 45px;
      width: 130px;
      margin-left: 14px;
    }
  }

  .recorder-panel {
    background-color: rgba($color: $primary-color, $alpha: 0.9);
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-bottom: 50px;

    p {
      color: white;
      margin: auto 0;
      text-align: center;
      padding: 1.8rem 1rem 0.4rem;
      font-weight: bolder;
    }

    .display-hidden {
      visibility: hidden;
    }

    .record-btn {
      height: 64px;
      width: 64px;
      background-color: #ff2525;
      margin: auto;
      position: absolute;
      top: -48px;
      left: 48%;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  .code__wrapper {
    position: absolute;
    background-color: rgba($color: #263238, $alpha: 0.9);
    bottom: calc(60px + 50px);
    left: 44%;
    border-radius: 18px;
    padding: 1.25rem;

    @media (max-width: 995px) {
      left: 28%;
    }

    h2 {
      color: white;
      text-align: center;
      font-weight: bolder;
      font-size: 32px;
    }

    p,
    h2 {
      margin: auto;
      padding: 0;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  video {
    height: 100vh !important;
    width: 100vw !important;
    object-fit: cover !important;
    background-color: #000;
  }

  [class^="video-recorder__Wrapper"] {

    // display: none;
    [class^="timer__Text"] {
      display: none;
    }

    [class^="render-actions"] {
      display: none;
      position: absolute;
      height: fit-content;
      top: unset;
      bottom: 0;

      [class^="record-button__RecWrapper"] {
        flex-direction: column-reverse;
      }
    }
  }
}