@import "src/styles/variables";

.subscriptions {
  h1 {
    margin-top: 1rem;
    font-weight: bolder;

    p {
      margin: 0 0 0 0.5rem;
      display: inline-block;
      text-transform: capitalize;
    }
  }

  .ant-row {
    row-gap: 0 !important;
  }

  .plans-wrapper {
    // margin-top: 2.5rem;
    min-height: 300px;
    align-items: center;

    .plans-wrapper__col {
      margin: .5rem 0;

      @media (max-width: 995px) {
        width: 100% !important;
      }

      @media (max-width: 770px) {
        margin-bottom: 30px;
      }
    }
  }
}