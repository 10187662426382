.dashboard__container {
  padding: 0 2.2rem;
  width: 100%;
  display: flex;

  .dashboard__container--one {
    margin: 0 0.75rem 0 0;
    width: 39%;
    height: 320px;
    border-radius: 30px;
    position: relative;
    overflow-x: hidden;
    border-top: 3px solid #2ab8f5;

    &::-webkit-scrollbar {
      display: none;
    }

    .dashboard__current--front {
      padding: clamp(1rem, 3rem, 3rem);
      width: 100%;
      height: 100%;
      color: #fff;
      background-color: #002f87;
      border-radius: 30px;

      p {
        margin: 0;
      }

      .current-valuation {
        .current-valuation--title {
          font-size: 16px;
        }

        .current-valuation--value {
          font-size: 40px;
          font-weight: bold;
        }
      }

      .statistics {
        margin: 1rem 0 2rem 0;
        display: flex;
        font-size: 16px;

        p {
          margin: 0 0.5rem 0 0;
          width: 50%;
          font-weight: 600;

          .icon-holdings-selected::before {
            margin-right: 5px;
            font-size: 20px;
            color: #2ab8f5;
          }

          img {
            margin: 0 0.5rem 0 0;
            width: 15px;
          }
        }
      }

      .total-investment {
        .total-investment--title {
          font-size: 16px;
        }

        .total-investment--value {
          font-size: 24px;
          font-weight: bold;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            margin-right: 15px;
          }
        }
      }

      .swipe-left {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-family: 24px;
        cursor: pointer;
        text-align: center;

        img {
          width: 25px;
          height: 25px;
          transition: all 500ms;
        }
      }

      .swipe-left:hover {
        img {
          transform: translateX(-10px);
        }
      }
    }

    .dashboard__current--side {
      padding: 3rem;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      border-radius: 30px;
      background-color: #002f87;

      .ant-list.ant-list-split {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ant-list-item {
              color: #fff;
              font-size: 15px;

              .font-light {
                font-weight: 100;
              }

              .bold {
                font-weight: bold;
              }
            }
          }
        }
      }

      .ant-list-item {
        padding: 0;
        border-bottom: 0;
      }

      .swipe-right {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        font-family: 24px;
        cursor: pointer;
        text-align: center;

        img {
          width: 25px;
          height: 25px;
          transition: all 500ms;
        }
      }

      .swipe-right:hover {
        img {
          transform: translateX(10px);
        }
      }
    }

    .dashboard__current--side.slide-in {
      animation: slideIn 350ms ease-in forwards;
    }

    .dashboard__current--side.slide-out {
      animation: slideOut 350ms ease-in forwards;
    }
  }

  .dashboard__shares {
    margin: 0 0 2rem 0.75rem;
    padding: 2rem 2rem 1rem;
    width: 59%;
    height: 320px;
    border-radius: 30px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 960px) {
      height: 280px;
    }

    @media (max-width: 500px) {
      height: 400px;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .shares__header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      p {
        margin: 0;
        font-size: 12px;
        font-style: italic;
        color: #707070;

        .icon-ic-info-24px::before {
          font-size: 12px;
          padding: 0.25rem;
          color: #b2b4b5;
        }
      }
    }

    .share-btn-mobile {
      Button {
        max-width: 320px;
        /*  max-width: 100%; */
        align-items: flex-start;
      }

      .text-bold {
        margin: 0 10px;
        margin-left: 10px !important;
      }
    }
    .shares__details--unit-container{
      .ant-radio-group {
          box-sizing: border-box;
          margin: 0;
          padding: 0.25rem;
          color: rgba(0, 0, 0, 0.85);
          font-size: 0.875rem;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          font-feature-settings: 'tnum', "tnum";
          display: inline-block;
          background: #01308620;
          border-radius: 0.625rem;
        }
      .ant-radio-button-wrapper {
        font: normal normal medium 13px/15px SF Pro Display;
        background: transparent;
        
       &:first-child{
        border-radius: 0.625rem;
        border: none;
        
       }
       &:last-child{
        border-radius: 0.625rem;
        border: none;
       }
       &:hover{
        background-color:  #013086;
        color: #FFF;
        --antd-wave-shadow-color:#013086;
       }
      }

      .ant-radio-button-wrapper-checked{
        background: #013086;
        border: none;
        --antd-wave-shadow-color:#013086;
        
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        border: none;
        background-color: #CCD5E7;
      }
      
    }

    .shares__details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .shares__details--amount {
        width: 150px;
        margin-top: 1.5625rem;

        .shares__details--amount--title {
          margin: 0;
        }

        .shares__details--amount--value {
          margin: 0;
          font-size: 22px;
          font-weight: bold;
          width: fit-content;
          border-bottom: 1px solid hsla(0, 0%, 75%, 0.5);
        }
      }
      .shares__details--unit-container{
        margin-top: -1.25rem;
      }

      .shares__details--input{
        outline: 0 !important;
        border-width: 0 0 0.0625rem !important;
        border-color: #707070;
      }

      @media (max-width: 500px) {
        .share-btn-pc {
          display: none;
        }
      }

      .shares__details--button {
        padding: 1rem 1.5rem 0 1rem;
        width: 700px;

        .ant-skeleton {
          width: 70px;
          height: 20px;
          margin: 0 .25rem;
          display: inline-block;
          vertical-align: sub;

          .ant-skeleton-button {
            width: 100%;
            height: 100%;
          }
        }

        @media (max-width: 1020px) {
          margin-right: -20px;
        }

        button {
          font-size: 17px;

          @media (max-width: 1020px) {
            margin-right: -20px;
          }

          .info-icon {
            margin: 0.25rem;
            width: 10px;
            height: 10px;
          }

          .text-bold {
            margin: 0 10px;
          }

          .icon-ic-info-24px {
            font-size: 12px;
            padding: 0.25rem;
          }
        }
      }

      .reset-icon {
        cursor: pointer;
        color: #b2b4b5;

        @media (max-width: 500px) {
          margin-right: 150px;
          max-width: 500px;
        }
      }
    }

    .shares__footer {
      display: flex;
      flex-wrap: wrap;

      .share-item {
        display: inline-block;
        opacity: 1;
        margin: 0.5rem 0.25rem;
        padding: 0.15rem 0.75rem;
        border-radius: 16px;
        cursor: pointer;
        border: 1px solid #b2b4b5;
        background: rgba(221, 221, 221, 0.3);

        &.disabled {
          cursor: progress;
        }
      }

      .ant-skeleton {
        margin: 0.5rem 0.25rem;

        &.ant-skeleton-element {
          width: auto;
        }
      }
    }
  }

  .dashboard__shares.buy-shares {
    border: 1.5px solid #ffa417;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #ffa417;
    }

    .ant-tabs-ink-bar {
      background-color: #ffa417;
    }

    .ant-btn.ant-btn-primary {
      background-color: #ffa417;
    }

    .share-item:focus,
    .share-item:active,
    .share-item:hover,
    .share-item.active {
      border: 1px solid #ffa417;
      color: #ffa417;
    }

    .share-item.danger:focus,
    .share-item.danger:active,
    .share-item.danger:hover,
    .share-item.danger.active {
      border: 1px solid #e45252;
      color: #e45252;
    }
  }

  .dashboard__shares.sell-shares {
    border: 1.5px solid #2ab8f5;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2ab8f5;
    }

    .ant-tabs-ink-bar {
      background-color: #2ab8f5;
    }

    .ant-btn.ant-btn-primary {
      background-color: #2ab8f5;
    }

    .share-item:focus:not(.share-item.disable),
    .share-item:active:not(.share-item.disable),
    .share-item:hover:not(.share-item.disable),
    .share-item.active:not(.share-item.disable) {
      border: 1px solid #2ab8f5;
      color: #2ab8f5;
    }

    .share-item.danger:focus:not(.share-item.disable),
    .share-item.danger:active:not(.share-item.disable),
    .share-item.danger:hover:not(.share-item.disable),
    .share-item.danger.active:not(.share-item.disable) {
      border: 1px solid #e45252;
      color: #e45252;
    }

    .share-item.disable {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.investment-summary__container {
    height: 2.6875rem;
    display: inline-block;
    max-width: 800px;
    background: #f0fafe 0% 0% no-repeat padding-box;
    border-radius: 0.875rem;
    opacity: 0.07;
    margin: 0 0 1.875rem 2rem;
    animation: slideRight 1000ms ease forwards;

  @media (max-width: 1080px) {
    max-width: 410px;
    height: auto;
    display: inline-flex;

    .investment-summary--divider {
      display: none;
    }

    .investment-summary__content {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;

      .investment-summary__content-investment {
        .investment-summary--investment--icon {
          margin-right: 0.1875rem;
        }
      }

      .investment-summary__content-count {
        padding-left: 0.375rem;
      }
    }
  }

  @media (max-width: 455px) {
    max-width: 325px;
    height: auto;
    display: inline-flex;
    font-size: 0.6875rem;

    .investment-summary--divider {
      display: none;
    }

    .investment-summary__content {
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;

      .investment-summary__content-investment {
        .investment-summary--investment--icon {
          margin-right: 0;
        }

        .investment-summary--heading {
          padding-top: 0.125rem;
        }

        .investment-summary--amount {
          padding-top: 0.125rem;
        }

      }

      .investment-summary__content-count {
        padding-left: 0.375rem;

      }
    }
  }


}
.investment-summary__content{
    display: inline-flex;
    padding: 0.65rem 1.5625rem 0.75rem 1.5625rem;
    .investment-summary__content-investment{
      display: flex;
    }
    .investment-summary__content-count{
      display: flex;
    }
    .investment-summary--investment--icon{
      width: 1.375rem;
      height: 1.125rem;
      margin-top: 0.1875rem;
      margin-right: 0.3125rem;
  }
    .investment-summary--heading{
      color: #707070 ;
    }
    .investment-summary--amount{
      color: #707070 ;
      padding-left: 0.3125rem;
    }

    .investment-summary--divider{
      border: 0.0625rem solid #2AB8F5;
      opacity: 0.25;
      height: 1.625rem;
      margin: -0.125rem 1.25rem 0 1.25rem ;
    }
    .investment-summary--icon-holdings::before{
      color: #2AB8F5;
      margin-top: 0.625rem;
    }
    .investment-summary--icon-holdings{
      padding-top: 0.25rem;
      padding-right: 0.3125rem;
    }
}
.dashboard__wallet {
   margin: 1rem 2.2rem 0rem 2rem;
}

@keyframes slideIn {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media (min-width: 500px) {
  .share-btn-mobile {
    display: none;
  }
}

@media (max-width: 960px) {
  .dashboard__container {
    flex-wrap: wrap;
    padding: 1rem;

    .dashboard__container--one {
      width: 100%;
      margin: auto;

      .dashboard__current--front {
        width: 100%;
        margin: auto;
      }
    }

    .dashboard__shares {
      margin: auto;
      margin-top: 20px;
      padding: 20px;

      width: 100%;
    }
  }

  .dashboard__wallet {
    margin: 1rem;
  }
}