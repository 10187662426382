@import "src/styles/variables";

.otp-modal {
	.ant-modal-content {
		border-radius: 30px;

		.ant-modal-close {
			// display: none;
			margin-top: 0.9375rem;
			margin-right: 1.25rem;
			&:hover{
				color: #EA5353;
			}
		}

		.ant-modal-header {
			padding: 2rem 2rem 0 2rem; 
			
			border-bottom: none;
			border-radius: 1.875rem;

			.ant-modal-title {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}

		.ant-modal-footer {
			display: none;
		}
	}
}

.o-t-p-form {
	// margin: auto;
	margin-left: 0.75rem;
	width: 90%;
	h1 {
		font-weight: bolder;
	}
	.otp-form__header {
		font: normal normal normal 16px/26px SF Pro Display;
		color: #303030;
		opacity: 1;
	}

	.otp__container {
		
		.resend__wrapper {
			margin-top: 1rem;
			display: flex;
			color: #b2b4b5;
			justify-content: flex-end;

			&.disable-resend {
				cursor: not-allowed;
			}
		}
	}

	.otp__wrapper {

		input {
			width: 3rem !important;
			border: none;
			border-bottom: 1px solid #b2b4b5;

			&:focus-visible {
				outline: none !important;
			}
		}
	}

	.submit-btn {
		display: flex;
		justify-content: center;
		margin-top: 3rem;
		margin-left: 1.25rem;

		.ant-btn.ant-btn-primary {
			border-radius: 12px;
			background: #002F87;
			border: none;
			width: 60% !important;
			height: 46px;
			text-align: center;

			&:disabled {
				color: #fff !important;
			}
		}
	}
}