@import "src/styles/variables";

.review-details {
}
.change-kenyan-id__container {
  .warning-icon {
    margin: 0 5px 0 0;
    color: #ffd12e;
  }
  form{
      .input-field__wrapper{
          margin: 2rem 0 1rem 0;
      }
  }
}
