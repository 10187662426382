@import "src/styles/variables";

.k-y-c-verification {
  .kyc__wrapper {
    padding: 0rem 1rem;
    width: 80%;
    @media (max-width: 995px) {
      width: 100% !important;
    }
  }
}
