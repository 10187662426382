.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5625rem 0 1.875rem 0;

    .logo {
        width: 110px;
        height: 44px;
    }
}

.order-container {
    margin: 0 auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 1.875rem 1.875rem #1C416D0F;
    border-radius: 1.875rem;
    opacity: 1;
    width: 600px !important;
    height: auto;

    .order__header {
        padding: 2rem 3rem 5rem;
        border-radius: 1.875rem 1.875rem 0 0;

        .order__header-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            word-wrap: break-word;
            color: #fff;
            margin: 0;

            h2 {
                margin: 0 0 1rem 0;
                color: #fff;
                font-size: 24px;
                font-weight: bold;
            }

            div {
                display: flex;
                align-items: center;
                min-width: 250px;
                width: 65%;
                font-size: 14px;

                p {
                    margin: 0.5rem 1rem 0.5rem 0;
                }

                img {
                    margin: 0 0.3125rem;
                    width: 15px;
                    height: 15px;
                }
            }

            .balance {
                text-align: end;
                margin-left: auto;
                font-weight: 600;
            }
        }
    }

    .order__body {
        padding: 6rem 1.5rem 1.5rem;
        border-radius: 0 0 -4rem 1.875rem;
        position: relative;
        font-size: 14px;
        word-wrap: break-word;

        .order__content {
            padding: 1.5rem;
            width: calc(100% - 6rem);
            min-height: 140px;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            text-align: center;
            border-radius: 1.875rem;
            box-shadow: 0 1.875rem 1.875rem rgba(28, 65, 109, .058823529411764705);

            .order__content-title {
                margin: 0;
                font-size: 16px;
            }

            h1 {
                margin: 0.5rem 0;
                font-size: 32px;
                font-weight: 600;
            }

            .order__content-amount {
                display: inline-flex;
                grid-gap: 1.25rem;
                gap: 1.25rem;
            }
        }

        .order__template {
            .input-field__wrapper {
                margin: 0 2rem;

                input {
                    height: 50px;
                }
            }

            .ant-list {
                margin: auto;
                width: 60%;
                min-width: 350px;

                .ant-list-item {
                    font-size: 15px;

                    .bold {
                        font-weight: bold;
                    }

                    .danger {
                        color: #ff6d3f;

                    }
                }
            }

            .payment_form__footer {
                margin: 1rem auto 0 auto;
                width: fit-content;

                img {
                    margin: 0 0.5rem;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .order__footer {
            text-align: center;
            padding: 2rem 2rem 1rem 00;

            .order__footer-terms {
                margin-bottom: 1.5625rem;
            }

            .terms-acceptance-label {
                font-size: 16px;

            }

            .order__footer-btn {
                display: inline-flex;
                margin: auto;
                width: 87%;
                gap: 1.125rem;
                margin-left: 1.25rem;

                .order__footer-decline {
                    background-color: #B2B4B5;
                    border-radius: 0.9375rem;
                    width: 80%;
                    height: 46px;
                }

                .order__footer-approve {
                    background-color: #43D5AE;
                    border-radius: 0.9375rem;
                    width: 80%;
                    height: 46px;
                }
            }
        }
    }

    .yellow-bg {
        background-color: #ffa417;
    }

    .blue-bg {
        background-color: #2ab8f5;
    }
}

.order-container-mobile {
    margin: 0 auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 1.875rem 1.875rem #1C416D0F;
    border-radius: 1.875rem;
    opacity: 1;
    width: 350px !important;
    height: auto;

    .order__header {
        padding: 2rem 1.5rem 5rem;
        border-radius: 1.875rem 1.875rem 0 0;

        .order__header-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            word-wrap: break-word;
            color: #fff;
            margin: 0;

            h2 {
                margin: 0 0 1rem 0;
                color: #fff;
                font-size: 24px;
                font-weight: bold;
            }

            div {
                display: flex;
                align-items: center;
                min-width: 250px;
                width: 65%;
                font-size: 14px;

                p {
                    margin: 0.5rem 0rem 0.5rem 0;
                }

                img {
                    margin: 0 0.3125rem;
                    width: 15px;
                    height: 15px;
                }
            }

            .balance {
                text-align: end;
                margin-left: auto;
                font-weight: 600;
            }
        }
    }

    .order__body {
        padding: 6rem 1.5rem 1.5rem;
        border-radius: 0 0 -4rem 1.875rem;
        position: relative;
        font-size: 14px;
        word-wrap: break-word;

        .order__content {
            padding: 1.5rem;
            width: calc(100% - 6rem);
            min-height: 140px;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            text-align: center;
            border-radius: 1.875rem;
            box-shadow: 0 1.875rem 1.875rem rgba(28, 65, 109, .058823529411764705);

            .order__content-title {
                margin: 0;
                font-size: 16px;
            }

            h1 {
                margin: 0.5rem 0;
                font-size: 32px;
                font-weight: 600;
            }

            .order__content-amount {
                display: inline-flex;
                grid-gap: 1.25rem;
                gap: 1.25rem;
            }
        }

        .order__template {
            .input-field__wrapper {
                margin: 0 2rem;

                input {
                    height: 50px;
                }
            }

            .ant-list {
                margin: auto;
                width: 60%;
                min-width: 300px;

                .ant-list-item {
                    font-size: 15px;

                    .bold {
                        font-weight: bold;
                    }

                    .danger {
                        color: #ff6d3f;
                    }
                }
            }

            .payment_form__footer {
                margin: 1rem auto 0 auto;
                width: fit-content;

                img {
                    margin: 0 0.5rem;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .order__footer {
            text-align: center;
            padding: 2rem 1rem 1rem 0;

            .order__footer-terms {
                margin-bottom: 1.5625rem;
                margin-left: 15px;
                text-align: left;
            }

            .terms-acceptance-label {
                font-size: 14px;
                margin: 0;
            }

            .order__footer-btn {
                display: inline-flex;
                margin: auto;
                width: 87%;
                gap: 18px;

                .order__footer-decline {
                    background-color: #B2B4B5;
                    border-radius: 0.9375rem;
                    width: 80%;
                    height: 46px;
                }

                .order__footer-approve {
                    background-color: #43D5AE;
                    border-radius: 0.9375rem;
                    width: 80%;
                    height: 46px;
                }
            }
        }
    }

    .yellow-bg {
        background-color: #ffa417;
    }

    .blue-bg {
        background-color: #2ab8f5;
    }
}

.success-animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .success-animation-text {
        font-size: 24px;
        font-weight: 900;
        color: #303030;
        opacity: 1;
        margin-top: -1.875rem;
    }

    .success-animation-text-mobile {
        font-weight: 900;
        color: #303030;
        opacity: 1;
        font-size: 20px;
        margin-left: 0.9375rem;
        margin-top: -3.4375rem;
    }
}

.decline-animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4.0625rem;

    .decline-animation-text {
        font-size: 24px;
        font-weight: 900;
        color: #303030;
        opacity: 1;
    }

    .decline-animation-text-mobile {
        font-weight: 900;
        color: #303030;
        opacity: 1;
        font-size: 1.25rem;
        margin-left: 0.9375rem;
    }
}

.text-bold {
    font-weight: bold;
    font-family: SFProDisplayBold !important;
}